import {Box, Typography} from '@mui/material';

type IndicatorProps = {
    text: string;
    icon: JSX.Element;
    reverse?: boolean | null | undefined;
    left?: boolean | null | undefined;
    small?: boolean | null | undefined;
};

export default function Indicator(props: IndicatorProps) {
    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent={props.left ? 'flex-start' : 'center'}
        >
            {props.reverse && props.icon}
            <Typography
                sx={{
                    alignSelf: props.left ? 'left' : 'center',
                    marginRight: '5px',
                    fontSize: props.small ? '14px' : '18px',
                    fontWeight: 'bold',
                }}
            >
                {props.text}
            </Typography>
            {!props.reverse && props.icon}
        </Box>
    );
}
