import {Box, BoxProps} from '@mui/material';
import Head from 'next/head';
import {useRouter} from 'next/router';
import React from 'react';
import {useTranslation} from 'next-i18next';
import {URL_CLIENT} from '../../utils/settings';

export const MAX_WIDTH = 1280;

export function Page(props: BoxProps & {children: React.ReactNode}) {
    return <Box sx={{p: 0, m: 0, maxWidth: MAX_WIDTH, margin: 'auto'}}>{props.children}</Box>;
}

export enum PageType {
    HOME = 'HOME',
    BASKET = 'BASKET',
    CONNECT = 'CONNECT',
    PAYMENT = 'PAYMENT',
    CONFIRMATION = 'CONFIRMATION',
    ACCOUNT = 'ACCOUNT',
    PROFILE = 'PROFILE',
    FAVORITES = 'FAVORITES',
    ORDERS = 'ORDERS',
    FAQ = 'FAQ',
    SUPPORT = 'SUPPORT',
    ROADMAP = 'ROADMAP',
    CGU = 'CGU',
    CGV = 'CGV',
    FOUNDERS = 'FOUNDERS',
    MENTION = 'MENTION',
    PASSWORD = 'PASSWORD',
    EMAIL = 'EMAIL',
    LOGIN = 'LOGIN',
    REGISTER = 'REGISTER',
    HISTORY = 'HISTORY',
}

type ContentSeo = {
    title: string;
    description: string;
    image?: string;
    width?: string;
    height?: string;
};

const CONTENT = {
    HOME: {
        title: 'home',
        description: 'Notre site de précommande vous accueille',
        image: 'https://storage.sbg.cloud.ovh.net/v1/AUTH_857d29cf94c54215b0da86e11c623b12/crowdfunding/icon/logo_footer.png',
        width: '1877',
        height: '734',
    } as ContentSeo,
    BASKET: {
        title: 'basket',
        description: 'Récapitulatif de votre panier',
    } as ContentSeo,
    CONNECT: {
        title: 'connect',
        description: 'Identifiez-vous afin de poursuivre votre commande',
    } as ContentSeo,
    PAYMENT: {
        title: 'payment',
        description: 'Afin de finaliser votre commande, payer le montant indiqué',
    } as ContentSeo,
    CONFIRMATION: {
        title: 'confirmation',
        description: 'Merci de votre commande',
    } as ContentSeo,
    ACCOUNT: {
        title: 'account',
        description: 'Votre compte',
    } as ContentSeo,
    PROFILE: {
        title: 'profile',
        description: 'Votre profil',
    } as ContentSeo,
    FAVORITES: {
        title: 'favorites',
        description: 'Votre liste de favoris',
    } as ContentSeo,
    ORDERS: {
        title: 'orders',
        description: 'Consulter mes dernières commandes',
    } as ContentSeo,
    FAQ: {
        title: 'faq',
        description: 'Consulter nos questions les plus fréquentes',
    } as ContentSeo,
    SUPPORT: {
        title: 'support',
        description: 'Écrivez-nous en cas de problème',
    } as ContentSeo,
    ROADMAP: {
        title: 'roadmap',
        description: 'La roadmap pour le future de Kunekune',
    } as ContentSeo,
    CGU: {
        title: 'cgu',
        description: "Consulter nos conditions générales d'utilisation",
    } as ContentSeo,
    CGV: {
        title: 'cgv',
        description: 'Consulter nos conditions générales de ventes',
    } as ContentSeo,
    FOUNDERS: {
        title: 'founders',
        description: 'Les fondateurs de Kunekune',
    } as ContentSeo,
    MENTION: {
        title: 'mention',
        description: 'Consulter mes dernières commandes',
    } as ContentSeo,
    PASSWORD: {
        title: 'password',
        description: 'Vous avez oublié votre mot de passe',
    } as ContentSeo,
    EMAIL: {
        title: 'email',
        description: 'Vous souhaitez vous connecter sans mot de passe',
    } as ContentSeo,
    LOGIN: {
        title: 'login',
        description: 'Merci de renseigner vos identifiants',
    } as ContentSeo,
    REGISTER: {
        title: 'register',
        description: 'Inscrivez-vous pour commander en ligne',
    } as ContentSeo,
    HISTORY: {
        title: 'history',
        description: 'Consulter les campagnes passées',
    } as ContentSeo,
};

type PageSeoProps = {
    page: PageType;
};

export function PageSeo(props: PageSeoProps) {
    const router = useRouter();
    const {t} = useTranslation('common');

    function getPathFromUrl(url) {
        return url.split(/[?#]/)[0];
    }

    const content = CONTENT[props.page];

    const key = 'page.' + content.title;
    const page = t(key);
    const title = `Kunekune | ${page}`;

    const asPath = router.asPath;
    // const basePath = router.basePath;
    // const pathname = router.pathname;
    // const route = router.route;

    // console.log('asPath = ', asPath);
    // console.log('basePath = ', basePath);
    // console.log('pathname = ', pathname);
    // console.log('route = ', route);
    // console.log('router = ', router);

    const path = getPathFromUrl(asPath);

    const urlFr = URL_CLIENT + path;
    const urlEn = URL_CLIENT + '/en' + path;

    // console.log('key = ', key);
    // console.log('page = ', page);
    // console.log('title = ', title);
    return (
        <Head>
            <title>{title}</title>
            <meta name="description" content={content.description} />
            <meta property="og:url" content={urlFr} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={content.description} />
            {content.image && <meta property="og:image" content={content.image} />}
            {content.image && <meta property="og:image:alt" content={title} />}
            {content.width && <meta property="og:image:width" content={content.width} />}
            {content.height && <meta property="og:image:height" content={content.height} />}
            <meta property="og:ttl" content="2419200" />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:description" content={content.description} />
            {content.image && <meta property="twitter:image" content={content.image} />}
            <meta property="twitter:image:alt" content={title} />
            <meta property="twitter:url" content={urlFr} />
            <meta property="twitter:site" content="@KuneKune" />
            <link hrefLang="fr" href={urlFr} rel="canonical" />
            <link hrefLang="en" href={urlEn} rel="alternate" />
        </Head>
    );
}
