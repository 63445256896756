export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
}

export function getFileName(name: string) {
    if (name.length > 50) {
        const end = name.substring(name.length - 15, name.length);
        const start = name.substring(0, 35);
        return `${start}...${end}`;
    } else {
        return name;
    }
}

export function getSize(size: number) {
    const k = 1024;
    const m = k * 1024;

    if (size >= 0 && size < k) {
        return size + 'o';
    } else if (size >= k && size < m) {
        return Math.ceil(size / k) + 'Ko';
    } else if (size >= m) {
        return Math.ceil(size / m) + 'Mo';
    } else {
        return size + 'o';
    }
}
