import {capitalizeFirstLetter} from './tool';

export function addDays(d: Date, days: number) {
    var date = new Date(d.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export function toMonthName(monthNumber: number, language: string) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return capitalizeFirstLetter(
        date.toLocaleString(language == 'fr' ? 'fr-FR' : 'en-US', {
            month: 'long',
        })
    );
}

export function getHourlyTime() {
    const now = new Date(Date.now());
    const stamp = `${now.getFullYear()}_${now.getMonth()}_${now.getDay()}_${now.getHours()}`;
    // console.log('stamp = ', stamp);
    return stamp;
}

export function getToday() {
    const now = new Date(Date.now());
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
    return today;
}

export function getDate(input: string) {
    const datetime = new Date(Date.parse(input));
    const date = new Date(
        datetime.getFullYear(),
        datetime.getMonth(),
        datetime.getDate(),
        0,
        0,
        0,
        0
    );
    return date;
}

export function getEndOfDay(input: string) {
    const datetime = new Date(Date.parse(input));
    const date = new Date(
        datetime.getFullYear(),
        datetime.getMonth(),
        datetime.getDate(),
        23,
        59,
        59,
        0
    );
    return date;
}
