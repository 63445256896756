import {useTranslation} from 'next-i18next';
import {useQueryClient} from 'react-query';
import {NotificationSeverity, useNotification} from '../../provider/NotificationProvider';
import {useUser} from '../../provider/UserProvider';
import {useGetCampaignLike, useLikeCampaign} from '../../queries/campaignQueries';
import {CAMPAIGN_GET_LIKE, CAMPAIGN_GET_URL} from '../../queries/keys';
import {CampaignDetails, CampaignLike} from '../../types';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {Colors} from '../common/colors';

type LikeButtonProps = {
    campaignId: string | null | undefined;
    campaignUrl: string | null | undefined;
};

export default function LikeButton(props: LikeButtonProps) {
    const {display} = useNotification();
    const {dbUser} = useUser();
    const queryClient = useQueryClient();
    const canFetch = Boolean(dbUser);
    const {data: dataLike, refetch: refetchLike} = useGetCampaignLike(canFetch);
    const likeCampaign = useLikeCampaign();
    const {t} = useTranslation('common');

    const isLiked = dataLike?.some((f) => f.campaign_id == props.campaignId);

    async function like() {
        if (!canFetch) {
            display(t('display.connected') + ' 🙄', NotificationSeverity.WARNING);
            return;
        }
        const result = await likeCampaign.mutateAsync(props.campaignUrl);

        const previousCampaignData = queryClient.getQueryData<CampaignDetails>(
            CAMPAIGN_GET_URL + ':' + props.campaignUrl
        );
        if (previousCampaignData) {
            previousCampaignData.like = result;
            queryClient.setQueryData<CampaignDetails>(
                CAMPAIGN_GET_URL + ':' + props.campaignUrl,
                {
                    ...previousCampaignData,
                }
            );
        }

        const previousLikeData = queryClient.getQueryData<CampaignLike[]>(CAMPAIGN_GET_LIKE);

        if (previousLikeData) {
            const untouchedData = previousLikeData.filter(
                (f) => f.campaign_id != props.campaignId
            );
            let newData = [] as CampaignLike[];
            if (isLiked) {
                newData = untouchedData;
            } else {
                newData = [
                    ...untouchedData,
                    {
                        campaign_id: props.campaignId,
                        created: Date.now().toString(),
                    } as CampaignLike,
                ];
            }

            queryClient.setQueryData<CampaignLike[]>(CAMPAIGN_GET_LIKE, newData);
        }
        display(isLiked ? t('display.nofollow') + ' 😅' : t('display.follow') + ' 🤩');
    }

    if (isLiked) {
        return (
            <FavoriteIcon
                sx={{cursor: 'pointer'}}
                onClick={like}
                style={{color: Colors.red}}
            />
        );
    } else {
        return (
            <FavoriteBorderIcon
                sx={{cursor: 'pointer'}}
                onClick={like}
                style={{color: Colors.red}}
            />
        );
    }
}
